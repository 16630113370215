<template>
  <div>
    <b-modal
      :visible="isModalActive"
      :title="$t('More Option')"
      ok-title="Accept"
      @change="(val) => $emit('update:is-modal-active', val)"
    >
      <b-overlay
        :show="dataLoading"
        rounded="sm"
      ><b-button
         id="toggle-btn"
         v-ripple.400="'rgba(113, 102, 240, 0.15)'"
         v-b-modal.optionType
         variant="outline-primary"
         block
       >
         {{ $t('Add more options') }}
       </b-button>
        <b-row>
          <b-col
            v-for="(item,index) in moreOption"
            :key="'OptionType_'+index"
            cols="12"
            class="mt-2"
          >
            <b-card
              no-body
            >
              <!-- body -->
              <b-card-body>
                <b-card-title><b-row>
                  <b-col
                    cols="6"
                  >{{ item.name }}</b-col>
                  <b-col
                    cols="6"
                  ><b-button
                    id="toggle-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    block
                    @click="addAttribute(index)"
                  >
                    {{ $t('Add attribute') }}
                  </b-button></b-col>
                </b-row></b-card-title>
              </b-card-body>
              <b-list-group flush>
                <b-list-group-item>
                  <b-row>
                    <b-col
                      cols="6"
                    >{{ $t('Name') }}</b-col>
                    <b-col
                      cols="6"
                    >{{ $t('Price') }}</b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
              <!-- kitchen sink link -->
              <b-list-group flush>
                <b-list-group-item
                  v-for="(attribute,attrIndex) in item.attribute"
                  :key="'Attr'+attrIndex"
                >
                  <b-row>
                    <b-col
                      cols="6"
                    >
                      <b-form-input
                        :id="'Attr'+attrIndex+'-name-input'"
                        v-model="moreOption[index].attribute[attrIndex].name"
                      />
                    </b-col>
                    <b-col
                      cols="6"
                    >
                      <b-form-input
                        :id="'Attr'+attrIndex+'-price-input'"
                        v-model="moreOption[index].attribute[attrIndex].price"
                      />
                    </b-col>
                  </b-row>

                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <div class="w-100">
          <p class="float-left mb-0" />
          <div
            class="float-right"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
              class="mr-2"
              @click="submit()"
            >
              {{ $t('Submit') }}
              <feather-icon
                icon="ShoppingBagIcon"
                size="12"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class=""
              @click="$emit('update:is-modal-active', false)"
            >
              {{ $t('Close') }}
            </b-button>
          </div>

        </div>
      </template>
    </b-modal>
    <!-- modal -->
    <b-modal
      id="optionType"
      ref="more-option"
      :title="$t('Add more options')"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          :state="nameState"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>

</template>

<script>

import {
  BModal,
  BCard,
  BCardBody,
  BCardTitle,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BFormCheckbox,
  BButton,
  BOverlay,
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import Detail from './components/Detail.vue'
import Timeline from './components/Timeline.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    BCard,
    BCardBody,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BTabs,
    // eslint-disable-next-line vue/no-unused-components
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BOverlay,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    Detail,
    // eslint-disable-next-line vue/no-unused-components
    Timeline,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      moreOption: [],
      dataLoading: false,
      name: '',
      nameState: null,
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id,
          } = this.data
          this.dataId = _id
          this.viewData()
        }
      }
    },
  },
  methods: {
    addAttribute(index) {
      this.moreOption[index].attribute.push({
        _id: null,
        name: '',
        price: '',
      })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.moreOption.push({
        _id: null,
        name: this.name,
        attribute: [{
          _id: null,
          name: '',
          price: '',
        }],
      })
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['more-option'].toggle('#toggle-btn')
      })
    },
    initValues() {
      this.dataId = null
      this.moreOption = []
    },
    viewData() {
      this.dataLoading = true
      store
        .dispatch(`${this.storeModuleName}/getMoreOption`, { id: this.dataId })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.moreOption = result.data.data
          this.dataLoading = false

        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.dataLoading = false

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    submit() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to update more option')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        // eslint-disable-next-line no-unused-vars
        .then(value => {
          if (value) {
            this.dataLoading = true
            store
              .dispatch(`${this.storeModuleName}/updateMoreOption`, { id: this.dataId, moreOption: this.moreOption })
            // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.dataLoading = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Success'),
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: this.$t('Success'),
                  },
                })
              }).catch(error => {
                this.dataLoading = false

                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                // console.log('fetchUsers Error : ', error)
                // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
              })
          }

        //  else {
        //   this.showToast(
        //     'warning',
        //     'top-right',
        //     `${this.$t('DELETE_CANCEL')}!`,
        //     'AlertCircleIcon',
        //     this.$t('DELETE_CANCEL'),
        //   )
        // }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
