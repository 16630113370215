<template>
  <div v-if="dataResponse!=null">

    <b-media
      vertical-align="top"
      class="mb-2"
    >
      <template #aside>
        <b-img
          v-if="dataResponse['bankSystem']['bankId']['imageBank']"
          :src="dataResponse['bankSystem']['bankId']['imageBank']"
          fluid
          thumbnail
          width="100px"
          :alt="dataResponse['bankSystem']['bankId']['_id']"
        />
        <b-img
          v-else
          :src="require('@/assets/images/blank/no_image.png')"
          fluid
          thumbnail
          width="100px"
          :alt="dataResponse['bankSystem']['bankId']['_id']"
        />
      </template>
      <h4 class="media-heading">
        {{ $t('Transfer to account') }} : {{ dataResponse['bankSystem']['name'] }}
      </h4>
      <b-card-text class="mb-0">
        {{ $t('Account number') }} : {{ dataResponse['bankSystem']['accountNumber'] }}
      </b-card-text>
      <b-card-text class="mb-0">
        {{ $t('Bank') }} : {{ showFromCurrentLanguage(dataResponse['bankSystem']['bankId']['name']) }} ({{ dataResponse['bankSystem']['bankId']['shortName'] }})
      </b-card-text>
    </b-media>

    <b-media
      vertical-align="top"
      class="mb-2"
    >
      <template #aside>
        <b-img
          v-if="dataResponse['bankMember']['bankId']"
          :src="dataResponse['bankMember']['bankId']['imageBank'] ? dataResponse['bankMember']['bankId']['imageBank'] : require('@/assets/images/blank/no_image.png')"
          fluid
          thumbnail
          width="100px"
          :alt="dataResponse['bankMember']['bankId'] ? dataResponse['bankMember']['bankId']['_id'] : ''"
        />
        <!-- <b-img
          v-else
          :src="require('@/assets/images/blank/no_image.png')"
          fluid
          thumbnail
          width="100px"
          :alt="dataResponse['bankMember']['bankId'] ? dataResponse['bankMember']['bankId']['_id'] : ''"
        /> -->
      </template>
      <h4
        v-if="dataResponse['bankMember']['bankId']"
        class="media-heading"
      >
        {{ $t('Transferred account') }} : {{ dataResponse['bankMember']['name'] }}
      </h4>
      <b-card-text
        v-if="dataResponse['bankMember']['bankId']"
        class="mb-0"
      >
        {{ $t('Account number') }} : {{ dataResponse['bankMember']['accountNumber'] }}
      </b-card-text>
      <b-card-text
        v-if="dataResponse['bankMember']['bankId']"
        class="mb-0"
      >
        {{ $t('Bank') }} : {{ dataResponse['bankMember']['bankId'] ? showFromCurrentLanguage(dataResponse['bankMember']['bankId']['name']) : '' }} ({{ dataResponse['bankMember']['bankId'] ? dataResponse['bankMember']['bankId']['shortName'] : '' }})
      </b-card-text>
      <b-card-text
        v-if="dataResponse['bankMember']['bankId']"
        class="mb-0"
      >
        {{ $t('Amount transferred') }} : {{ dataResponse['amount'] / 100 }}
      </b-card-text>
      <b-card-text
        v-if="dataResponse['bankMember']['bankId']"
        class="mb-0"
      >
        {{ $t('Transfer time') }} : {{ dataResponse['dateSlip'] | formatDateTime }}
      </b-card-text>
    </b-media>
    <b-card-text class="mb-0">
      {{ $t('Slip') }}
    </b-card-text>
    <b-img
      v-if="dataResponse['slip']"
      thumbnail
      fluid
      :src="dataResponse['slip']"
      :alt="dataResponse['_id']"
    />
    <b-img
      v-else
      :src="require('@/assets/images/blank/no_image.png')"
      fluid
      thumbnail
      width="100px"
      :alt="dataResponse['_id']"
    />
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
  BImg,
  BMedia,

} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BImg,
    BMedia,

  },
  directives: {
    Ripple,
  },
  props: {
    dataResponse: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>
